<template>
<docs-layout>
  <div class="prose">
    <p>
      Refering to 
      <asom-link href="https://tailwindcss.com/docs/font-family" target="blank">
        TailwindCSS documentation about typography
      </asom-link>
      for more information of how to customize typography in app.
    </p>
    <p>
      Refering to <asom-link href="https://tailwindcss-typography.netlify.app/">Tailwind typography</asom-link> for info of package for typography.
    </p>
    <p>Currently we use `@tailwind/typography` to set default font size and font weight</p>
    <p>The default font family is directly picked up from Tailwind's website</p>
    <p>Here is the list of typography</p>
    <table class="AsomTable">
      <caption class="hidden"></caption>
      <thead>
        <tr>
          <th scope="col">Element</th>
          <th scope="col">Example</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><h1>h1</h1></td>
          <td><h1>The quick brown fox jumps over the fence</h1></td>
        </tr>
        <tr>
          <td><h2>h2</h2></td>
          <td><h2>The quick brown fox jumps over the fence</h2></td>
        </tr>
        <tr>
          <td><h3>h3</h3></td>
          <td><h3>The quick brown fox jumps over the fence</h3></td>
        </tr>
        <tr>
          <td><p>p</p></td>
          <td><p>The quick brown fox jumps over the fence</p></td>
        </tr>
        <tr>
          <td>list</td>
          <td>
            This is a list
            <ul>
              <li>Item 1</li>
              <li>Item 2</li>
              <li>Item 3</li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</docs-layout>
</template>
<script>
import DocsLayout from '@/docs/DocsLayout'

export default {
  components: {
    DocsLayout,
  },
  data() {
  }
}
</script>